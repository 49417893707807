@font-face {
  font-family: 'Sharp Sans';
  src: local('sharp'),
    url('../src/assets/fonts/sharp/Sharp\ Sans.otf') format('opentype');
  font-weight: 600;
}
@font-face {
  font-family: 'Sharp Sans Bold Italic';
  src: local('sharp'),
    url('../src/assets/fonts/sharp/Sharp\ Sans\ Bold\ Italic.otf')
      format('opentype');
  font-weight: 900;
}
@font-face {
  font-family: 'Sharp Sans Medium';
  src: local('sharp'),
    url('../src/assets/fonts/sharp/Sharp\ Sans\ Medium.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'Sharp Sans Semibold';
  src: local('sharp'),
    url('../src/assets/fonts/sharp/Sharp\ Sans\ Semibold.otf')
      format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: 'Sharp Sans Bold';
  src: local('sharp'),
    url('../src/assets/fonts/sharp/SharpSansBold.otf') format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: 'Sharp Sans Display No1';
  font-weight: 600;
  src: url('../src/assets/fonts/SharpSansDisplayNo1-Semibold.ttf');
}
@font-face {
  font-family: 'Sharp Sans Display No1';
  font-weight: 700;
  src: url('../src/assets/fonts/SharpSansDisplayNo1-Bold.ttf');
}
@font-face {
  font-family: 'Brixton_Lead';
  font-weight: 400;
  src: url('../src/assets/fonts/Brixton_Wood-Vector.woff2');
}
@font-face {
  font-family: 'Sharp Sans Extrabold';
  src: local('sharp'),
    url('../src/assets/fonts/sharp/Sharp\ Sans\ Extrabold.otf')
      format('opentype');
  font-weight: bold;
}

body {
  margin: 0;
  background-color: #fafafa !important;
  font-family: 'Sharp Sans Medium', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
